.header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: $spacing-5 0;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > a {
      width: 180px;
    }

    ul {
      display: none;
      margin-top: $spacing-3;
      li {
        margin-right: $spacing-6;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &.is-open {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100vh;
    background: $white;
    nav {
      ul {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100px;

        li {
          margin-bottom: $spacing-2;
        }
      }
    }
    .header__menu-mobile {
      top: 2px;
      span {
        &:first-child {
          position: relative;
          top: 5px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
}

.header__menu-mobile {
  position: relative;
  top: 2px;
  cursor: pointer;

  span {
    display: block;
    width: 20px;
    height: 2px;
    background: $black;
    margin-bottom: 3px;
    transition: transform 0.2s $timing-cubic;
    &:last-child  {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $grid-breakpoints-sm) {
  .header {
    padding-top: 45px;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .header {
    padding-top: $spacing-7;
    padding-bottom: 69px;

    nav {
      flex-direction: row;

      > a {
        width: auto;
      }

      ul {
        display: block;
        margin-top: 0;
      }
    }
  }

  .header__menu-mobile {
    display: none;
  }
}

@media (min-width: 1441px) {
  .header {
    padding-top: 96px;
  }
}
