@use 'sass:math';

// Grid
$grid-breakpoints-sm: 768px;
$grid-breakpoints-md: 1024px; // Medium screen / tablet
$grid-breakpoints-lg: 1240px; // Large screen / desktop
$grid-columns-mobile: 4;
$grid-columns-tablet: 12;
$grid-columns-desktop: 12;
$grid-gutter-sm: 16px;
$grid-gutter-md: 24px;
$grid-gutter-lg: 32px;
$max-width: 1960px;
$offset-enable: true !default; // Turn this value to false if you don't want to use offset

@mixin make-col($columns, $grid-columns) {
  width: percentage(math.div($columns, $grid-columns));
}
@mixin make-offset($columns, $grid-columns) {
  margin-left: percentage(math.div($columns, $grid-columns));
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.row {
  margin-left: -$grid-gutter-sm;

  &:after {
    display: table;
    clear: both;
    content: '';
  }
}

.col {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: $grid-gutter-sm;
  width: 100%;
}

.col--center {
  margin-left: auto;
  margin-right: auto;
  float: none;
  padding-left: 0;
}
@for $i from 1 to $grid-columns-mobile {
  .col-xs-#{$i} {
    @include make-col($i, $grid-columns-mobile);
  }
}
@media (min-width: $grid-breakpoints-md) {
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }

  .row {
    margin-left: -$grid-gutter-md;
  }

  .col {
    padding-left: $grid-gutter-md;
  }
  @for $i from 1 to $grid-columns-tablet + 1 {
    .col-md-#{$i} {
      @include make-col($i, $grid-columns-tablet);
    }
    @if $offset-enable {
      .col-md-offset-#{$i} {
        @include make-offset($i, $grid-columns-tablet);
      }
    }
  }
}

@media (min-width: $grid-breakpoints-sm) {
  .container {
    padding-left: 45px;
    padding-right: 45px;
  }
}

// @media (min-width: $grid-breakpoints-md) {
//   .container {
//     padding-left: 32px;
//     padding-right: 32px;
//   }
// }

@media (min-width: $grid-breakpoints-lg) {
  .container {
    max-width: $max-width;
  }

  .row {
    margin-left: -$grid-gutter-lg;
  }
  .col {
    padding-left: $grid-gutter-lg;
  }
  @for $i from 1 to $grid-columns-desktop + 1 {
    .col-lg-#{$i} {
      @include make-col($i, $grid-columns-desktop);
    }
    @if true {
      .col-lg-offset-#{$i} {
        @include make-offset($i, $grid-columns-desktop);
      }
    }
  }
}
