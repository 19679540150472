.tag {
  display: block;
  padding: 0 30px;
  background: rgba(#000000, 0.25);
  color: rgba(#ffffff, 0.6);
  font-size: $fts-small;
  height: 52px;
  line-height: 52px;
  border-radius: 50px;
}
