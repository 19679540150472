/* Spacing */
$spacing-1: 8px;
$spacing-2: 16px;
$spacing-3: 24px;
$spacing-4: 32px;
$spacing-5: 40px;
$spacing-6: 48px;
$spacing-7: 56px;
$spacing-8: 64px;
$spacing-9: 72px;
$spacing-10: 80px;
$spacing-11: 88px;

/* Font familly */
$ftf-default: 'Arbeit';
$ftf-heading: 'Mondia';

/* Font Size */
$fts-default: 20px;
$fts-large: 30px;
$fts-small: 18px;
$fts-xs: 12px;

/* Colors system and naming https://material.io/design/color/the-color-system.html#tools-for-picking-colors */
/* Main colors */

:root {
  --black: #262626;
  --gray-900: #23262d;
  --gray-800: #424242;
  --gray-700: #545454;
  --gray-600: #757575;
  --gray-500: #9e9e9e;
  --gray-400: #bdbdbd;
  --gray-300: #e0e0e0;
  --gray-200: #eaeaea;
  --gray-100: #f2f2f2;
  --white: #f2f2f2;
}

[data-theme='dark'] {
  --black: #f2f2f2;
  --gray-900: #f2f2f2;
  --gray-800: #eaeaea;
  --gray-700: #e0e0e0;
  --gray-600: #bdbdbd;
  --gray-500: #9e9e9e;
  --gray-400: #757575;
  --gray-300: #616161;
  --gray-200: #424242;
  --gray-100: #23262d;
  --white: #000;
}

$primary-500: #d9583c;

$primary-100: lighten($primary-500, 40%);
$primary-200: lighten($primary-500, 30%);
$primary-300: lighten($primary-500, 20%);
$primary-400: lighten($primary-500, 10%);
$primary-600: darken($primary-500, 10%);
$primary-700: darken($primary-500, 20%);
$primary-800: darken($primary-500, 30%);
$primary-900: darken($primary-500, 40%);

/* Basic colors*/
$blue: #004b98;
$red: #e7221f;
$green: #6bad46;
$orange: #ff6c37;

/* Black and white */
$black: var(--black);
$gray-900: var(--gray-900);
$gray-800: var(--gray-800);
$gray-700: var(--gray-700);
$gray-600: var(--gray-600);
$gray-500: var(--gray-500);
$gray-400: var(--gray-400);
$gray-300: var(--gray-300);
$gray-200: var(--gray-200);
$gray-100: var(--gray-100);
$white: var(--white);

/* Size */
$radius: 4px;
$radius-small: 2px;

/* Shadow */
$shadow1: 0 3px 8px rgba(51, 51, 51, 0.08);
$shadow2: 0 8px 16px rgba(51, 51, 51, 0.08);

/* Transition */
$timing-cubic: cubic-bezier(0.25, 0.1, 0.25, 1);

/* z-index */
$z-layers: (
  'overlay': 200,
  'has-overlay': 201,
  'header': 10,
  'wrapper-mobile': 2,
  'topbar': 3,
  'menu': 2,
  'menu-club': 4,
  'filter': 1,
  'cookie': 9,
);

@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }

  @return map-get($z-layers, $layer);
}
