h1,
.h1 {
  font-size: 64px;
  line-height: 64px;
  font-weight: 700;
  @media (min-width: $grid-breakpoints-sm) {
    font-size: 112px;
    line-height: 0.88em;
    letter-spacing: -0.03em;
  }

  @media (min-width: $grid-breakpoints-md) and (max-height: 1440px) {
    font-size: 13.5vh;
  }

  @media (min-width: $grid-breakpoints-lg) and (max-height: 1440px) {
    font-size: 19vh;
  }

  @media (min-width: $grid-breakpoints-lg) and (min-height: 1440px) {
    font-size: 250px;
    line-height: 250px;
  }
}

h2,
.h2 {
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
  @media (min-width: $grid-breakpoints-sm) {
    font-size: 100px;
    line-height: 110px;
  }
}

h3,
.h3 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  @media (min-width: $grid-breakpoints-md) {
    font-size: 45px;
    line-height: 56px;
  }
}

h4,
.h4 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
}

// Copy
.ft-primary {
  font-size: $fts-default;
  line-height: 28px;
}

.ft-secondary {
  font-size: $fts-small;
  line-height: 20px;
}

.ft-tertiary {
  font-size: $fts-xs;
  line-height: 20px;
}

.ft-opacity {
  line-height: 28px;
  opacity: 0.5;
}

// Helper
.ft-up {
  text-transform: uppercase;
}
.ft-cap {
  text-transform: capitalize;
}
.ft-center {
  text-align: center;
}
.ft-right {
  text-align: right;
}
.ft-italic {
  font-style: italic;
}
.ft-nowrap {
  white-space: nowrap;
}
.ft-strikethrough {
  text-decoration: line-through;
}
.ft-default {
  font-family: $ftf-default;
}
.ft-heading {
  font-family: $ftf-heading;
}

.ft-100 {
  font-weight: 100;
}
.ft-300 {
  font-weight: 300;
}
.ft-400 {
  font-weight: 400;
}
.ft-500 {
  font-weight: 500;
}
.ft-600 {
  font-weight: 600;
}
.ft-700 {
  font-weight: 700;
}
.ft-900 {
  font-weight: 900;
}

.ft-truncat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-primary {
  color: $primary-500;
}
.c-red {
  color: $red;
}

.c-black {
  color: $black;
}

.c-gray-900 {
  color: $gray-900;
}
.c-gray-800 {
  color: $gray-800;
}
.c-gray-700 {
  color: $gray-700;
}
.c-gray-600 {
  color: $gray-600;
}
.c-gray-500 {
  color: $gray-500;
}
.c-gray-400 {
  color: $gray-400;
}
.c-gray-300 {
  color: $gray-300;
}
.c-gray-200 {
  color: $gray-200;
}
.c-gray-100 {
  color: $gray-100;
}

.c-white {
  color: $white;
}
