@font-face {
  font-family: 'Arbeit';
  src: url('../../static/fonts/arbeit-book.woff2') format('woff2');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arbeit';
  src: url('../../static/fonts/arbeit-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arbeit';
  src: url('../../static/fonts/arbeit-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mondia';
  src: url('../../static/fonts/mondia-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mondia';
  src: url('../../static/fonts/mondia-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
