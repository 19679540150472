.list-num {
  margin-top: $spacing-7;
  margin-bottom: $spacing-10;

  li {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-4;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      transition: all 0.3s $timing-cubic;
      font-size: 45px;
      opacity: 0.11;
    }
    p {
      transition: all 0.3s $timing-cubic;
      opacity: 0.1;
    }

    br {
      display: none;
    }

    &.is-active {
      span {
        color: $primary-500;
        opacity: 1;
      }
      p {
        color: $black;
        opacity: 1;
      }
    }
  }
}

@media (min-width: $grid-breakpoints-sm) {
  .list-num {
    margin-top: 0;

    li {
      flex-direction: row;
      align-items: center;

      span {
        width: 140px;
        min-width: 140px;
        font-size: 105px;
        margin-right: $spacing-5;
      }
    }
  }
}

@media (min-width: $grid-breakpoints-md) {
  .list-num {
    margin-bottom: 175px;

    li {
      margin-bottom: 100px;

      span {
        width: 190px;
        min-width: 190px;
        text-align: center;
        margin-right: $spacing-11;
      }
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .list-num {
    padding-left: $spacing-7;
    li {
      br {
        display: block;
      }
    }
  }
}
