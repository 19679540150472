.mosaic {
  display: grid;
  text-align: center;
  grid-row-gap: $spacing-4;
}

.mosaic--experts {
  text-align: left;
}

.mosaic--core {
  > div:nth-child(2) {
    display: none;
  }
}

@media (min-width: $grid-breakpoints-sm) {
  .mosaic {
    grid-template-columns: repeat(2, 1fr);
  }

  .mosaic--experts {
    grid-row-gap: $spacing-5;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .mosaic {
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 109px;
    grid-column-gap: $spacing-9;
  }

  .mosaic--experts {
    grid-column-gap: $spacing-9;
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .mosaic {
    grid-template-columns: repeat(4, 1fr);
  }

  .mosaic--experts {
    grid-template-columns: repeat(4, 1fr);
  }

  .mosaic--core {
    > div:nth-child(2) {
      display: block;
    }
  }
}
