.footer {
  background: $black;
  color: $white;
  padding: $spacing-6 0;

  .col {
    margin-bottom: $spacing-3;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    transition: all 0.3s $timing-cubic;
    &:hover {
      color: $gray-400;
    }
  }

  img {
    pointer-events: none;
    user-select: none;
  }

  ul {
    display: flex;

    li {
      margin-right: $spacing-3;
      &:last-child {
        margin-right: 0;
      }
      a {
        svg path {
          transition: all 0.3s $timing-cubic;
        }

        &:hover {
          svg path {
            fill: $gray-400;
          }
        }
      }
    }
  }
}

@media (min-width: $grid-breakpoints-md) {
  .footer {
    padding: 110px 0;
    ul {
      justify-content: flex-end;
    }
  }
}
