.link {
  &:hover {
    color: $gray-700;
  }

  &.is-active {
    color: $gray-500;
    &:hover {
      color: $black;
    }
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.link-arrow {
  display: inline-block;
  position: relative;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  color: $primary-500;
  transform: translateX(-25px);
  span {
    transition: opacity 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin-right: 4px;
    opacity: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 28px;
    right: 0;
    display: block;
    width: calc(100% - 27px);
    height: 1px;
    background: $primary-500;
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0.86, 0, 0.07, 1);
  }
  &:hover {
    transform: translateX(0);
    span {
      opacity: 1;
    }
    &:before {
      opacity: 0;
    }
  }
}

.link-highlighted {
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $white;
    position: absolute;
    bottom: -1px;
    transition: all 0.3s $timing-cubic;
  }

  &:hover {
    color: $gray-500;
    &:before {
      background: $gray-500;
    }
  }
}
