.section {
  padding: $spacing-10 0;
  .h3:not(p) {
    font-size: 64px;
    line-height: 72px;
    margin-bottom: $spacing-5;
  }
  .h2:not(p) {
    margin-bottom: $spacing-3;
  }
}

.section-header {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.section-header__paragraph {
  margin-top: $spacing-3;
}

.section-header__arrow {
  display: none;
  position: absolute;
  bottom: -125px;
  right: 0;
}

.section-thesis {
  margin-top: $spacing-9;
  margin-bottom: $spacing-9;
}

.section-gray {
  background: $gray-200;
  .h4 br {
    display: none;
  }
}

.section-black {
  margin-left: -20px;
  margin-right: -20px;
  background: $black;
  color: $white;
  padding: $spacing-10 $spacing-2;
}

.section-black__container {
  margin-top: $spacing-7;

  p.h2.ft-heading {
    br {
      display: none;
    }
  }

  ul li {
    display: inline-block;
    margin-right: $spacing-2;
    margin-bottom: $spacing-2;
    &:last-child {
      margin-right: 0;
    }
  }
}

.section-black__p-opacity {
  opacity: 0.6;
  margin: 0 auto;
}

.section-core {
  margin: $spacing-10 0;
}

.section-experts {
  margin-bottom: $spacing-10;
}

.section-about {
  position: relative;
}

.section-about__photos {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;

  &.is-hidden img {
    opacity: 0;
    transform: translateY(50px);
  }

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    opacity: 1;
    transform: translateY(0);
    transition: opacity .4s, transform .4s;
    will-change: opacity, transform;
  }

  img:nth-child(2) {
    transition-delay: .05s;
  }
}

.section-about__button {
  position: relative;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
  
  &::after {
    position: absolute;
    z-index : 2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
  }
}

@media (min-width: $grid-breakpoints-sm) {
  .section {
    .h3:not(p) {
      font-size: 68px;
    }
  }

  .section-header {
    margin-bottom: 200px;
    flex-direction: initial;
    align-items: flex-end;

    .h1 {
      text-align: right;
    }
  }

  .section-header--default {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  .section-header__paragraph {
    position: relative;
    top: 130px;
    max-width: 80%;
  }

  .section-header__arrow {
    display: block;
  }

  .section-black {
    padding-left: 45px;
    padding-right: 45px;
  }

  .section-black__p-opacity {
    max-width: 340px;
  }

  .section-about__photos img {
    height: 800px;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .section {
    .h2:not(p) {
      margin-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 130px;
  }

  .section-thesis {
    margin-top: calc(187px + 130px);
    margin-bottom: 166px;
  }

  .section-header__paragraph {
    width: 100%;
    margin-top: 0;
    max-width: 295px;
  }

  .section-black__container {
    margin-top: 136px;

    p.h2.ft-heading {
      br {
        display: block;
      }
    }

    ul {
      display: flex;
      li {
        margin-right: 30px;
      }
    }
  }

  .section-core {
    margin-top: 0;
    margin-bottom: 119px;
  }

  .section-about__photos {
    display: flex;

    img {
      width: 50%;
      height: initial;
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  .section {
    padding-top: 198px;
    padding-bottom: 253px;

    .h3:not(p) {
      margin-bottom: 127px;
    }
  }

  .section-header__paragraph {
    width: 25%;
  }

  .section-black {
    padding-top: 161px;
    padding-bottom: 175px;
    padding-left: 8.33%;
    padding-right: 8.33%;
  }

  .section-black__container {
    margin-top: 192px;
  }

  .section-gray {
    padding: 253px 0;

    .h4 br {
      display: block;
    }
  }

  .section-gray__container {
    margin-left: -$spacing-4;
  }

  .section-experts {
    margin-bottom: 226px;

    .link-twitter svg {
      position: relative;
      top: 1px;
    }
  }
}

